<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_top" @click="$router.replace('/vipCenter/recharge')">
            <img src="../../../../assets/img/pro_fan.png" alt="" />
          </div>
          <div class="titles">充值记录</div>
          <div class="center_top">
            <div class="date-filter">
              <span>筛选时间：</span>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="begin_date"
                type="date"
                placeholder="开始时间"
                @change="chooseStart"
              >
              </el-date-picker>
              <div class="line"></div>
              <el-date-picker
                :clearable="false"
                :editable="false"
                v-model="end_date"
                type="date"
                placeholder="结束时间"
                @change="chooseEnd"
              >
              </el-date-picker>
            </div>
            <div class="recharge_ways-filter">
              <span>筛选方式：</span>
              <el-select v-model="wayValue" placeholder="请选择充值方式">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <button type="button" @click="getRechargeRecord">确定</button>
            </div>
          </div>
          <div class="list_head">
            <div class="list_ever">编号</div>
            <div class="list_ever">时间</div>
            <div class="list_ever">明细</div>
            <div class="list_ever">金额</div>
            <div class="list_ever">状态</div>
          </div>
          <div class="list">
            <div class="loop" v-for="(item, index) in list" :key="index">
              <div class="loop_over">{{ item.id }}</div>
              <div class="loop_over">
                {{ (item.create_at * 1000) | format("YYYY-MM-DD HH:mm") }}
              </div>
              <div class="loop_over">
                {{
                  item.type == 1 ? "微信" : item.type == 2 ? "支付宝" : "汇款"
                }}充值
              </div>
              <div class="loop_over">{{ item.amount / 100 }}</div>
              <div
                class="loop_over"
                :style="
                  item.status == 0
                    ? 'color: #FF411D;'
                    : item.status == 1
                    ? 'color: #008417;'
                    : ''
                "
              >
                {{
                  item.status == 0
                    ? "审核中"
                    : item.status == 1
                    ? "审核成功"
                    : "审核失败"
                }}
              </div>
            </div>
          </div>

          <el-pagination
            class="page"
            :page-size="limit"
            background
            layout="prev, pager, next"
            :total="count"
            :current-page="page"
            @current-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRechargeRecord } from "@network/recharge";
export default {
  name: "Detailed",
  data() {
    return {
      list: [],
      count: 0,
      page: 1,
      limit: 10,
      begin_date: "",
      end_date: "",
      startDate: "",
      endDate: "",

      options: [
        {
          value: "",
          label: "请选择",
        },
        {
          value: "1",
          label: "微信",
        },
        {
          value: "2",
          label: "支付宝",
        },
        {
          value: "3",
          label: "转账充值",
        },
      ],
      wayValue: "",
    };
  },
  created() {
    this.getRechargeRecord();
  },
  components: {},
  methods: {
    format(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    //选择开始时间
    chooseStart() {
      this.startDate =
        new Date(this.begin_date).getFullYear() +
        "" +
        this.format(new Date(this.begin_date).getMonth() + 1) +
        "" +
        this.format(new Date(this.begin_date).getDate());
      //   this.list = [];
      this.page = 1;
    },
    //选择结束时间
    chooseEnd() {
      this.endDate =
        new Date(this.end_date).getFullYear() +
        "" +
        this.format(new Date(this.end_date).getMonth() + 1) +
        "" +
        this.format(new Date(this.end_date).getDate());
      //   this.list = [];
      this.page = 1;
    },

    handleSizeChange(val) {
      this.page = val;
      this.getRechargeRecord();
    },
    getRechargeRecord() {
      getRechargeRecord(
        this.page,
        this.limit,
        this.startDate,
        this.endDate,
        this.wayValue
      ).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取充值记录失败",
            offset: 300,
          });
          return false;
        }
        this.list = res.data;
        this.count = res.count;
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "@assets/css/vipCenter/ChildComps/rechargeRecord.less";
</style>
