import {request} from './request'

//获取充值记录
export function getRechargeRecord(page,limit,begin,end,type){
    return request({
        url:"/mine/recharge/record",
        params:{
            page,limit,begin,end,type
        }
    })
}
//获取充值记录详情
export function getRechargeRecordInfo(id){
    return request({
        url:"/mine/recharge/info",
        params:{
            id
        }
    })
}

export function getRechargeOpen(){
    return request({
        url:"/mine/recharge"
    })
}
//获取充值信息
export function offlinePayInfo(){
    return request({
        url:"/mine/recharge/offline",
    })
}

//充值
export function offlinePay(amount,name){
    return request({
        method:"POST",
        url:"/mine/recharge/offline",
        data:{
            amount,name
        }
    })
}

//支付宝
export function alipay(amount){
    return request({
        method:'post',
        url:"/mine/recharge/alipay",
        data:{
            amount
        }
    })
}